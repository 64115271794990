import React, { useEffect } from "react"
import styled from "styled-components"

const Circle = (props) => {
    const {className, children} = props;

    useEffect(() => {
        const scroll_pos = 0;
        const animation_begin_pos = 100; //where you want the animation to begin
        const animation_end_pos = 800; //where you want the animation to stop
        const beginning_color = [255, 212, 59]//we can set this here, but it'd probably be better to get it from the CSS; for the example we're setting it here.
        const ending_color = [80, 171, 173]; //what color we want to use in the end
        // Change color on scroll
        const [red, green, blue] = [83, 100, 204];
        const [new_red, new_green, new_blue] = [78, 236, 145];
        const circle = document.getElementsByClassName("main-circle")[0];
        const text = document.getElementsByClassName("circle-text")[0];

        window.addEventListener('scroll', () => {
        // const y = 1 + (window.scrollY || window.pageYOffset) / 500;
        // const [r, g, b] = [red/y, green/y, blue/y].map(Math.round);
        // circle.style.backgroundColor = `rgb(${r}, ${g}, ${b})`

        const percent_scrolled =  (window.scrollY || window.pageYOffset) /  ( animation_end_pos - animation_begin_pos )
        // console.log("percent scrolled", percent_scrolled)
        const [new_r, new_g, new_b ] = [
            Math.round(beginning_color[0] + (ending_color[0] - beginning_color[0]) * percent_scrolled),
            Math.round(beginning_color[1] + (ending_color[1] - beginning_color[1]) * percent_scrolled),
            Math.round(beginning_color[2] + (ending_color[2] - beginning_color[2]) * percent_scrolled),
        ]
        circle.style.backgroundColor = `rgb(${new_r}, ${new_g}, ${new_b})`
        if (window.scrollY > 700) {
            text.style.opacity = `${percent_scrolled - 0.2}`;
        }
        })
    }, []);

    return (
        <Shape
        className={className}
        >
            {children}
        </Shape>
    );
};

const Shape = styled.div`
    height: 60rem;
    width: 60rem;
    border-radius: 50%;
    margin-top: -10%;
    display: inline-block;
    z-index: 0;
    position: relative;
`

export default Circle;


