import React from "react"

import styled from "styled-components"

const CircleText = (props) => {
    const {className, children} = props;

    return (
        <Text
        className={className}
        >
            {children}
        </Text>
    );
};

const Text = styled.div`
    position: absolute;
    bottom: 26%;
    font-size: 26px;
    color: #F9F9F9;
    letter-spacing: 1.56px;
    text-align: center;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1;
`

export default CircleText;